<template>
  <div>
    <v-sheet
      v-if="loaded"
      elevation="3"
      rounded="true"
      outline
      class="mb-4 pa-4"
    >
      <div v-if="'invoice' in payData">
        <h2>Invoice</h2>
        <v-row>
          <label class="col-sm-3 text-subtitle-2">Bill To</label>
          <div class="col-sm-9">{{ payData.invoice.fullname }}</div>
        </v-row>
        <v-row>
          <label class="col-sm-3 text-subtitle-2">Invoice No</label>
          <div class="col-sm-9">{{ payData.invoice.invoice_no }}</div>
        </v-row>
        <v-row>
          <label class="col-sm-3 text-subtitle-2">Document Date</label>
          <div class="col-sm-9">{{ payData.invoice.invoice_date }}</div>
        </v-row>
        <v-row>
          <label class="col-sm-3 text-subtitle-2">Description</label>
          <div class="col-sm-9">{{ payData.invoice.description }}</div>
        </v-row>
        <v-row>
          <label class="col-sm-3 text-subtitle-2">Items</label>
          <div class="col-sm-9 px-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left d-none d-sm-table-cell">No</th>
                    <th class="text-left">Description</th>
                    <th class="text-center">Qty</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in payData.invoice.items" :key="item.id">
                    <td class="d-none d-sm-table-cell">{{ item.item_no }}</td>
                    <td>{{ item.description }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                    <td class="text-right">
                      {{ $helpers.toCurrency(item.amount_subtotal) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-row>
      </div>

      <div v-else-if="payData.customer">
        <h2>FeePlus Online Payment</h2>
        <v-row>
          <label class="col-sm-3 text-subtitle-2 label">Bill To</label>
          <div class="col-sm-9 field">{{ payData.customer.fullname }}</div>
        </v-row>
        <v-row>
          <label class="col-sm-3 text-subtitle-2">Items</label>
          <div class="col-sm-9 px-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left d-none d-sm-table-cell">No</th>
                    <th class="text-left">Description</th>
                    <th class="text-center">Qty</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(sc, index) in payData.service_customers"
                    :key="sc.service_customer_id"
                    :class="sc.quantity ? '' : 'up-to-date'"
                  >
                    <td class="d-none d-sm-table-cell">{{ index + 1 }}</td>
                    <td>
                      <b>{{ sc.name }}</b
                      ><br />
                      <span v-if="sc.quantity"
                        >{{ sc.date_text }}
                        <v-chip v-if="sc.passes" small color="warning"
                          >{{ sc.passes * sc.quantity }} passes</v-chip
                        ></span
                      >
                      <span v-else
                        >Valid Until {{ toDate(sc.valid_until) }}</span
                      >&nbsp;
                      <v-chip label small color="green lighten-3">{{
                        frequencyToWord(sc.feefreq_num, sc.feefreq_unit)
                      }}</v-chip>
                    </td>
                    <td class="text-center">
                      {{ sc.quantity ? sc.quantity : "" }}
                    </td>
                    <td class="text-right">
                      <span v-if="sc.quantity">{{
                        $helpers.toCurrency(sc.totalfee)
                      }}</span>
                      <v-chip v-else label small color="success"
                        >Up to date</v-chip
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-row>
      </div>

      <v-row>
        <label class="col-sm-3 text-subtitle-2">Payable Amount</label>
        <div class="col-sm-9 text-h6">{{ $helpers.toCurrency(payamount) }}</div>
      </v-row>

      <v-row>
        <v-col>
          <v-card v-if="payData.uploads_pending.length">
            <v-toolbar color="orange" dark>
              <v-toolbar-title><v-icon>mdi-alert</v-icon> Uploads Pending Processing</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-list>
              <template v-for="(upload, index) in payData.uploads_pending">
                <v-list-item :key="index">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        <!-- <v-chip
                  class="ma-2"
                  color="green"
                  text-color="white"
                  small
                >
                  <v-avatar
                    left
                    class="green darken-4"
                  >
                    {{ upload.files_count }}
                  </v-avatar>
                  Files
                </v-chip> -->
                        <v-chip color="orange" text-color="white" small>
                          {{ upload.files_count }}
                          <v-icon right small>
                            mdi-file
                          </v-icon>
                        </v-chip>
                        <!-- {{ upload.files_count }} <v-icon small>mdi-file</v-icon> -->
                        {{ upload.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ upload.createdtime }}
                        {{
                          upload.remark
                            ? " - " + upload.remark.replace(/\r?\n|\r/g, " ")
                            : ""
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="'invoice' in payData && payamount == 0">
        <v-col>
          <v-alert prominent type="success">
            This invoice has been fully paid.
          </v-alert>
        </v-col>
      </v-row>

      <template v-else>

      <template v-if="uploadSuccess === false">
        
        <v-tabs grow v-model="tab" background-color="lime lighten-3">
          <v-tab :disabled="!payonline_enable">Pay Online</v-tab>
          <!-- :disabled="!payonline_enable" -->
          <v-tab>Upload Payment Slip</v-tab>
          <!-- :disabled="!enable_upload" -->
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-form
                v-if="payonline_enable && payamount > 0"
                ref="formPayment"
              >
                <v-row>
                  <label class="col-sm-3 text-subtitle-2">Email</label>
                  <div class="col-sm-9">
                    <span v-if="invoice.email">{{ invoice.email }}</span>
                    <span
                      v-else-if="
                        'customer' in payData && payData.customer.email
                      "
                      >{{ payData.customer.email }}</span
                    >
                    <v-text-field
                      v-else
                      v-model="email"
                      :rules="emailRules"
                      label="Email"
                      placeholder="Please provide your email"
                      single-line
                      outlined
                      dense
                      id="formEmail"
                    ></v-text-field>
                  </div>
                  <label class="col-sm-3 text-subtitle-2">Mobile Phone</label>
                  <div class="col-sm-9">
                    <span v-if="invoice.p_mobile">{{ invoice.p_mobile }}</span>
                    <span
                      v-else-if="
                        'customer' in payData && payData.customer.p_mobile
                      "
                      >{{ payData.customer.p_mobile }}</span
                    >
                    <v-text-field
                      v-else
                      v-model="p_mobile"
                      :rules="p_mobileRules"
                      label="Mobile Number"
                      placeholder="Please provide your mobile number"
                      single-line
                      outlined
                      dense
                      id="formPMobile"
                    ></v-text-field>
                  </div>
                </v-row>
              </v-form>
              <v-row>
                <v-col v-if="payonline_enable" cols="12" sm="9" offset-sm="3">
                  <div v-if="payamount > 0">
                    <v-alert type="warning" class="mt-5">
                      By clicking Pay Now button below, you agree that the
                      services listed above and the total fees are correct. If
                      there is any discrepancies, please do not proceed. Contact
                      the centre administrator immediately and rectify the
                      differences.
                    </v-alert>
                    <v-btn color="success" @click="payNow()" :loading="loading"
                      >Pay Now</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-alert type="warning">
                      Unable to proceed. Payable amount is zero.
                    </v-alert>
                  </div>
                </v-col>
                <v-col v-else cols="12" sm="8" offset-sm="2">
                  <v-alert type="error" class="mt-5">
                    FeePlus Online Payment is not available. Please contact
                    {{ business.name }} for payment alternatives.
                  </v-alert>
                </v-col>
                <v-col cols="12"
                  ><v-img src="@/assets/images/eghl.png"></v-img>
                  <p class="text-caption text-center">
                    Payment made in FeePlus is processed through GHL ePayments
                    Sdn Bhd (eGHL) - an Internet Payment Service Provider.
                  </p></v-col
                >
              </v-row>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card v-if="enable_upload" flat class="pt-3">
              <v-row v-if="payinfo">
                <label class="col-sm-3 text-subtitle-2">Payment Methods</label>
                <div class="col-sm-9">
                  <!-- <v-btn
                    color="indigo"
                    class="ma-2"
                    small
                    outlined
                  >
                  <v-icon
                    >
                      mdi-qrcode
                    </v-icon>
                    View QR Code
                  </v-btn> -->
                  <div v-html="payinfo" @click="payInfoHandleClick"></div>
                  <pay-info-dialog
                    v-if="payInfoDialog.show"
                    @close="payInfoDialog.show = false"
                    :imageTitle="payInfoDialog.title"
                    :imageSrc="payInfoDialog.src"
                  ></pay-info-dialog>
                </div>
              </v-row>
              <v-row>
                <label class="col-sm-3 text-subtitle-2">Description</label>
                <div class="col-sm-9">
                  <v-text-field
                    v-model="description"
                    label="Description"
                    single-line
                    outlined
                    dense
                    id="description"
                    :disabled="loading"
                  ></v-text-field>
                </div>
                <label class="col-sm-3 text-subtitle-2 label"
                  >Upload Document</label
                >
                <!-- <div class="col-sm-9">
                  <v-file-input
                    ref="doc"
                    v-on:change="handleFileUpload()"
                    show-size
                    label="Document (Max size: 3MB)"
                    placeholder="Attach the pdf / image document file"
                    accept="image/png, image/jpeg, application/pdf"
                    :rules="fileRules"
                    outlined
                    dense
                    small-chips
                  >
                  </v-file-input>
                </div> -->
                <div class="col-sm-9">
                  <v-simple-table dense v-if="documents.length">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Filename
                          </th>
                          <th class="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in documents" :key="item.name">
                          <td>
                            <div class="mt-2 mb-2">
                              {{ item.name }} ({{
                                $helpers.bytesToSize(item.size)
                              }})
                            </div>
                            <!-- <v-text-field
                              label="Document description"
                              single-line
                              outlined
                              dense
                              :value="item.name"
                            ></v-text-field> -->
                            <v-combobox
                              label="File Description"
                              dense
                              clearable
                              outlined
                              :items="docDesc"
                              v-model="documents[index].description"
                              :disabled="loading"
                            ></v-combobox>
                          </td>
                          <td class="text-center">
                            <v-icon
                              :disabled="loading"
                              color="red darken-2"
                              @click="deleteDoc(index)"
                              title="Delete document"
                            >
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <input
                    id="fileUpload"
                    type="file"
                    hidden
                    v-on:change="handleFileUpload()"
                    accept="image/png, image/jpeg, application/pdf"
                  />
                  <v-btn
                    color="warning"
                    @click="selectDoc()"
                    :loading="loadingDoc"
                    :disabled="loading"
                    >Select File</v-btn
                  >
                </div>
                <label class="col-sm-3 text-subtitle-2">
                  Remark
                </label>
                <div class="col-sm-9">
                  <v-textarea
                    v-model="remark"
                    outlined
                    name="input-7-4"
                    label="Remark"
                    value=""
                    :disabled="loading"
                  ></v-textarea>
                </div>
                <v-col cols="12" sm="9" offset-sm="3">
                  <v-alert
                    v-if="loading && uploadProgress > 0"
                    color="info"
                    dense
                    outlined
                    icon="mdi-cloud-upload"
                    class="mb-2"
                  >
                    <v-progress-linear
                      color="info"
                      height="25"
                      :value="uploadProgress"
                      :indeterminate="uploadProgress == 100"
                    ></v-progress-linear>
                    <span>Uploading your files</span>... {{uploadProgress}}% <span v-if="uploadProgress == 100">Processing...</span>
                  </v-alert>

                  <v-btn color="success" @click="uploadDoc()" :loading="loading"
                    >Submit</v-btn
                  >
                  <!-- Uploading your files, please wait for the system to continue.
                  &nbsp;<span class="body-2" v-if="loading && uploadProgress > 0">Uploading your files... {{uploadProgress}}%</span> -->
                </v-col>
              </v-row>
            </v-card>
            <v-card v-else flat>
              <v-row>
                <v-col cols="12" sm="8" offset-sm="2">
                  <v-alert type="error" class="mt-5">
                    FeePlus Upload Payment Slip is not available. Please contact
                    {{ business.name }} for payment notification alternatives.
                  </v-alert>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>

      <v-row v-else>
        <v-col>
          <v-alert prominent type="success">
            Payment slip uploaded for <strong>{{ uploadSuccess }}</strong><br>
            <v-btn color="primary" small @click="reload">Make another payment</v-btn>
          </v-alert>
        </v-col>
      </v-row>

      </template>
    </v-sheet>

    <!-- <v-sheet v-else-if="payData.customer" elevation="3" rounded="true" outline class="mb-4 pa-4">
          <h2>FeePlus Online Payment</h2>
          <v-form ref="formPayment">
          <v-row>
            <label class="col-sm-3 text-subtitle-2 label">Bill To</label>
            <div class="col-sm-9 field">{{ payData.customer.fullname }}</div>
            <label class="col-sm-3 text-subtitle-2">Items</label>
            <div class="col-sm-9 px-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left d-none d-sm-table-cell">No</th>
                      <th class="text-left">Description</th>
                      <th class="text-center">Qty</th>
                      <th class="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sc, index) in payData.service_customers" :key="sc.service_customer_id" :class="sc.quantity?'':'up-to-date'">
                      <td class="d-none d-sm-table-cell">{{ index + 1 }}</td>
                      <td>
                        <b>{{ sc.name }}</b><br>
                        <span v-if="sc.quantity">{{ sc.date_text }} <v-chip v-if="sc.passes" small color="warning">{{sc.passes * sc.quantity}} passes</v-chip></span> <span v-else>Valid Until {{ toDate(sc.valid_until) }}</span> <v-chip label small color="green lighten-3">{{ frequencyToWord(sc.feefreq_num, sc.feefreq_unit) }}</v-chip>
                      </td>
                      <td class="text-center">{{ sc.quantity ? sc.quantity : '' }}</td>
                      <td class="text-right">
                        <span v-if="sc.quantity">{{ $helpers.toCurrency(sc.totalfee) }}</span>
                        <v-chip v-else label small color="success">Up to date</v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <label class="col-sm-3 text-subtitle-2">Description</label>
            <div class="col-sm-9">{{ description }}</div>
            <label class="col-sm-3 text-subtitle-2">Payable Amount</label>
            <div class="col-sm-9 text-h6">{{ $helpers.toCurrency(this.payamount) }}</div>
            <div v-if="payonline_enable" >
            <label class="col-sm-3 text-subtitle-2">Email</label>
            <div class="col-sm-9">
              <span v-if="payData.customer.email">{{ payData.customer.email }}</span>
              <v-text-field
                v-else
                v-model="email"
                :rules="emailRules"
                label="Email"
                placeholder="Please provide your email"
                single-line
                outlined
                dense
                id="formEmail"
              ></v-text-field>
            </div>
            <label class="col-sm-3 text-subtitle-2">Mobile Phone</label>
            <div class="col-sm-9">
              <span v-if="payData.customer.p_mobile">{{ payData.customer.p_mobile }}</span>
              <v-text-field
                v-else
                v-model="p_mobile"
                :rules="p_mobileRules"
                label="Mobile Number"
                placeholder="Please provide your mobile number"
                single-line
                outlined
                dense
                id="formPMobile"
              ></v-text-field>
            </div>
            </div>
            <v-col v-if="payonline_enable" cols="12" sm="9" offset-sm="3">
              <v-alert type="warning">
                By clicking Pay Now button below, you agree that the services listed above and the total fees are correct. If there is any discrepancies, please do not proceed. Contact the centre administrator immediately and rectify the differences.
              </v-alert>
              <v-btn color="success" @click="payNow()" :loading="loading">Pay Now</v-btn>
            </v-col>
            <v-col v-else cols="12" sm="9" offset-sm="3">
              <v-alert type="error">
                FeePlus Online Payment is not available.
              </v-alert>
            </v-col>
            <v-col cols="12"><v-img src="@/assets/images/eghl.png"></v-img>
            <p class="text-caption text-center">Payment made in FeePlus is processed through GHL ePayments Sdn Bhd (eGHL) - an Internet Payment Service Provider.</p></v-col>
          </v-row>
          </v-form>
        </v-sheet> -->
    <!-- </v-container>
    </v-main> -->
    <NotFound v-if="notFound" />
  </div>
</template>
<script>
// import Navigation from "@/components/Navigation";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  props: {
    cid: {
      required: true,
    },
    iid: {
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
      notFound: false,
      dcid: null,
      invoice: {},
      payData: {},
      payonline_enable: false,
      enable_upload: true,
      payinfo: null,
      loading: false,
      payment_id: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      email: "",
      p_mobileRules: [(v) => !!v || "Mobile number is required"],
      p_mobile: "",
      description: "",
      payamount: 0.0,
      direct_access: "",
      documents: [],
      // document: "",
      remark: "",
      loadingDoc: false,
      uploadSuccess: false,
      docDesc: ["Payment Slip", "Voucher"],
      tab: null,
      payInfoDialog: {
        show: false,
        title: '',
        src: ''
      }
      // fileRules: [
      //   (value) =>
      //     !value ||
      //     value.size < 3000000 ||
      //     "File size should be less than 3 MB!",
      // ],
    };
  },
  async created() {
    if (!this.customer.id) {
      await this.$store.dispatch("fetchCustomer");
    }
    this.dcid = this.$store.getters.isDependant(this.cid) ? this.cid : null;
    if (this.customer.id != this.cid && this.dcid === null) {
      // TODO check for email and access_token
      let q = this.$route.query;
      if ("access_token" in q) {
        this.direct_access = {
          email: this.cid,
          access_token: q.access_token,
        };
      } else {
        // this.$router.replace({ name: "HomeMain" });
        // return;
        this.notFound = true;
      }
    }
    await this.fetchData();
  },
  methods: {
    reload(){
      this.$emit('reload')
    },
    payInfoHandleClick(e) {
      if (e.target.matches('.open-image')) {
        e.preventDefault();
        this.payInfoDialog.title = e.target.getAttribute('data-title');
        this.payInfoDialog.src = e.target.getAttribute('data-src');
        this.payInfoDialog.show = true;
      }
    },
    async fetchData() {
      let o = {
        dcid: this.dcid,
        direct_access: this.direct_access
      }
      if(this.iid > 0){
        o.type = "fetchInvoiceForPayment",
        o.iid = this.iid
      } else {
        o.type = "fetchDataForPayment"
      }
      await this.$store
        .dispatch(o)
        .then((d) => {
          if (d.error == false) {
            this.processPayData(d);
            this.payonline_enable = d.payonline;
            this.enable_upload = d.enable_upload_payment_slip !== 0;
            this.payinfo = d.payinfo;
            this.loaded = true;
          } else {
            this.notFound = true;
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            text: e,
          });
        });
      // 20210712 - combine both calling into one
      // if (this.iid > 0) {
      //   await this.$store
      //     .dispatch({
      //       type: "fetchInvoiceForPayment",
      //       iid: this.iid,
      //       dcid: this.dcid,
      //       direct_access: this.direct_access,
      //     })
      //     .then((d) => {
      //       // fetch customer data
      //       if (d.error == false) {
      //         // this.invoice = d.invoice;
      //         this.processPayData(d);
      //         this.payonline_enable = d.payonline;
      //         this.loaded = true;
      //       } else {
      //         // error - show main page
      //         // this.$router.replace({
      //         //   name: "HomeMain",
      //         // });
      //         this.notFound = true;
      //       }
      //     })
      //     .catch((e) => {
      //       Swal.fire({
      //         icon: "error",
      //         text: e,
      //       });
      //     });
      // } else {
      //   await this.$store
      //     .dispatch({
      //       type: "fetchDataForPayment",
      //       dcid: this.dcid,
      //       direct_access: this.direct_access,
      //     })
      //     .then((d) => {
      //       if (d.error == false) {
      //         this.processPayData(d); //this.payData = d;
      //         this.payonline_enable = d.payonline;
      //         this.loaded = true;
      //       } else {
      //         // error - show main page
      //         // this.$router.replace({
      //         //   name: "HomeMain",
      //         // });
      //         this.notFound = true;
      //       }
      //     })
      //     .catch((e) => {
      //       Swal.fire({
      //         icon: "error",
      //         text: e,
      //       });
      //       this.loading = false;
      //     });
      // }
    },
    processPayData(payData, addQty) {
      if('invoice' in payData){
        this.description = `Invoice: ${payData.invoice.invoice_no} - `+payData.invoice.description;
        this.payamount = payData.invoice.total_amount - payData.invoice.total_paid;
      } else {
        this.payamount = 0.0;
        let n = [], // name
          dr = [], // day range
          h = { description: '', totalfee: 0.0 }, // highest
          gotPayment = false;
        // console.log('payData', payData.service_customers);
        // console.log('addQty', addQty);
        let loopCount = 0;
        payData.service_customers.map((sc) => {
          if (sc.valid_until) {
            let dateFromCalc = moment(sc.valid_until).add(1, "day"),
              today = moment(),
              qty = 0;
            sc.datefrom = dateFromCalc.format("YYYY-MM-DD");
            // 20240201 - convert feefreq_unit to words
            let feeFreqUnit = '';
            switch(sc.feefreq_unit){
              case 'd': feeFreqUnit = 'days'; break;
              case 'w': feeFreqUnit = 'weeks'; break;
              case 'm': feeFreqUnit = 'months'; break;
              case 'y': feeFreqUnit = 'years'; break;
              default: // error - do not run loop, show up to date
            }
            if(feeFreqUnit != ''){
              loopCount = 0; // 20240201 - safe guard to stop looping if it is not stop!
              while ((dateFromCalc.diff(today, "days") < 0 || qty < addQty) && loopCount < 60) {
                dateFromCalc.add(sc.feefreq_num, feeFreqUnit); // sc.feefreq_unit.toUpperCase()
                qty += 1;
                loopCount++;
              }
            }
            sc.dateto = dateFromCalc.subtract(1, "day").format("YYYY-MM-DD");
            sc.quantity = qty;
            if (qty) {
              gotPayment = true;
              sc.totalfee = this.$helpers.toCurrency(sc.fee) * sc.quantity;
              // generate date range text
              let d = sc.name, // description
                dt = "", // date_text
                dateFrom = moment(sc.datefrom),
                dateTo = moment(sc.dateto),
                monthBegin = moment([
                  dateFrom.get("year"),
                  dateFrom.get("month"),
                ]),
                monthEnd = moment(monthBegin).endOf("month");
              if (
                monthBegin.isSame(dateFrom, "day") &&
                monthEnd.isSame(dateTo, "day")
              ) {
                dt = monthBegin.format("MMMM YYYY");
                d = d + " - " + dt;
              } else {
                if (dateFrom.isSame(dateTo, "day")) {
                  dt = dateFrom.format("D-MMM-YYYY");
                  d += " (" + dt + ")";
                } else {
                  dt =
                    dateFrom.format("D-MMM-YYYY") +
                    " - " +
                    dateTo.format("D-MMM-YYYY");
                  d += " (" + dt + ")";
                }
              }
              if (sc.passes) d += " - " + sc.passes * sc.quantity + " passes";
              // sc.datefrom_text = this.toDate(sc.datefrom)
              // sc.dateto_text = this.toDate(sc.dateto)
              sc.description = d;
              sc.date_text = dt;
              this.payamount += sc.totalfee;
              n.push(sc.name);
              dr.push(sc.date_text);
              if (sc.totalfee > h.totalfee) h = sc;
            }
          }
          return sc;
        });
        if (!gotPayment && payData.service_customers.length) return this.processPayData(payData, 1); // reprocess payData with qty 1
        // generate description
        let desc = this.getMajorWords(n, 1);
        if (desc == "") {
          // get highest
          desc = h.description; // h.name+' ('+h.datefrom_text+' - '+h.dateto_text+')'+(h.passes ? ' - '+(h.passes * h.quantity)+' passes':'')
        } else {
          let drDesc = this.getMajorWords(dr);
          if (drDesc !== "") desc += " (" + drDesc + ")";
        }
        this.description = desc;
      }
      this.payData = payData;
    },
    toDate(d) {
      return moment(d).format("D-MMM-YYYY");
    },
    frequencyToWord(feefreq_num, feefreq_unit) {
      let sss = feefreq_num > 1 ? "s" : "";
      switch (feefreq_num + feefreq_unit) {
        case "1w":
          return "Weekly";
        case "2w":
          return "Bi-weekly";
        case "1m":
          return "Monthly";
        case "2m":
          return "Bi-monthly";
        case "3m":
          return "Quarterly";
        case "6m":
          return "Semi-anually";
        case "1y":
          return "Anually";
        case "0":
          return "N/A";
        default:
          switch (feefreq_unit) {
            case "d":
              return feefreq_num + " day" + sss;
            case "w":
              return feefreq_num + " week" + sss;
            case "m":
              return feefreq_num + " month" + sss;
            case "y":
              return feefreq_num + " year" + sss;
          }
      }
    },
    getMajorWords(arr, joined) {
      let len = arr.length,
        mjr = len / 2, // majority is 1/2, but i chose 1/3 to easily get the word
        wordCounts = {},
        wordOrders = [];
      if (joined) arr = arr.join(" ").split(" ");
      arr.forEach(function(v) {
        //value
        if (v.length == 1 && "-*|,\":<>[]{}`';()@&$#%".indexOf(v) != -1) return;
        else if (v in wordCounts) wordCounts[v] += 1;
        else {
          wordOrders.push(v);
          wordCounts[v] = 1;
        }
      });
      let result = [];
      wordOrders.forEach(function(v) {
        if (wordCounts[v] > mjr) result.push(v);
      });
      return result.join(" ");
    },
    checkPaymentResponse(r) {
      if (r.error == false) {
        if (this.payment_id == r.payment.payment_id) {
          let swalIcon = "error";
          switch (r.payment.status) {
            case 1:
              swalIcon = "success";
              break;
            case 2:
            case 3:
            case 4:
              swalIcon = "warning";
              break;
            default:
              swalIcon = "error";
          }
          Swal.fire({
            title: r.title,
            html: r.description,
            icon: swalIcon,
          }).then(() => {
            if (swalIcon !== "error") {
              this.$router.go(-1); // on success / pending (warning) - go back
            }
          });
        }
      }
    },
    payNow() {
      let postData = {
          payamount: this.payamount,
        },
        invalid = [];
      if (
        !(
          this.invoice.email ||
          ("customer" in this.payData && this.payData.customer.email)
        )
      ) {
        if (this.email) postData.email = this.email;
        else invalid.push("email address");
      }
      if (
        !(
          this.invoice.p_mobile ||
          ("customer" in this.payData && this.payData.customer.p_mobile)
        )
      ) {
        if (this.p_mobile) postData.p_mobile = this.p_mobile;
        else invalid.push("mobile phone number");
      }
      if (invalid.length) {
        this.$refs.formPayment.validate();
        Swal.fire({
          icon: "error",
          text: "Please provide a valid email address and mobile phone number.",
        });
        return;
      }
      this.loading = true;
      let postObject = {};
      if ('invoice' in this.payData) { // this.invoice.id
        postObject = {
          type: "createPaymentForInvoice",
          iid: this.payData.invoice.id, //this.invoice.id,
          dcid: this.dcid,
          direct_access: this.direct_access,
          payment: postData,
        };
      } else {
        // payData
        postData.description = this.description;
        postData.items = [];
        let i = 1;
        this.payData.service_customers.forEach((sc) => {
          if (!sc.quantity) return; // stop iteration if no quantity
          let ii = {};
          ii.item_no = i;
          ii.service_id = sc.service_id;
          ii.service_customer_id = sc.service_customer_id;
          ii.item_type = "s";
          ii.description = sc.description; // sc.name+' ('+sc.datefrom_text+' - '+sc.dateto_text+')'+(sc.passes ? ' - '+(sc.passes * sc.quantity)+' passes':'')
          ii.quantity = sc.quantity;
          ii.amount = sc.totalfee;
          ii.amount_subtotal = ii.amount;
          ii.service_datefrom = sc.datefrom;
          ii.service_dateto = sc.dateto;
          ii.passes = sc.passes ? sc.passes * sc.quantity : null;
          ii.create_service_customer = 0;
          ii.gst_code = "";
          ii.email_subscription = 0;
          postData.items.push(ii);
          i++;
        });
        postObject = {
          type: "createPayment",
          dcid: this.dcid,
          direct_access: this.direct_access,
          payment: postData,
        };
      }
      this.$store
        .dispatch(postObject)
        .then((r) => {
          if (r.error == false) {
            if (typeof r.eGHL != "undefined") {
              this.payment_id = r.payment_id;
              window.onmessage = (e) => {
                if (
                  typeof e.data == "object" &&
                  "type" in e.data &&
                  e.data.type == "paymentDone"
                ) {
                  this.checkPaymentResponse(e.data.response);
                }
              };
              this.$helpers.postForeign(r.eGHL_URL, r.eGHL);
            }
          } else {
            if ("message" in r)
              Swal.fire({
                icon: "error",
                text: r.message,
              });
            else console.error(r);
            this.loading = false;
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            text: e,
          });
          this.loading = false;
        });
    },
    selectDoc() {
      if (this.documents.length >= 2)
        Swal.fire({
          icon: "warning",
          text: "You can upload maximum of 2 documents only.",
        });
      else document.getElementById("fileUpload").click();
    },
    deleteDoc(index) {
      // filename
      // console.log('filename', filename);
      // this.documents.splice(this.documents.findIndex(e => e.name === filename),1); // this is by filename
      this.documents.splice(index, 1); // this is by index
    },
    resizeBase64Img(base64, maxWidth = 960, maxHeight = 960, type = 'image/jpeg') { // 20210907
      return new Promise((resolve) => {
        var image = new Image();
        image.onload = () => {
          // Resize the image
          var canvas = document.createElement('canvas'),
            // max_size = 544,// TODO : pull max size from a site config
            width = image.width,
            height = image.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          // var dataUrl = canvas.toDataURL(type); // 'image/jpeg'
          resolve(canvas.toDataURL(type));
        }
        image.src = base64;
      });
    },
    handleFileUpload() {
      // console.log('file', this.$refs.doc);
      let input = document.getElementById("fileUpload"),
        file = input.files[0]; //this.$refs.doc.$refs.input.files[0];
      if (file) {
        if (this.documents.some((d) => d.name == file.name)) {
          Swal.fire({
            icon: "error",
            text: "File name exist",
          });
        } else {
          // console.log('file',file, file.name);
          this.loadingDoc = true;
          this.toBase64(file).then((content) => {
            let type = content.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0],
              { name , size } = file,
              pushDoc = () => {
                this.documents.push({
                  name,
                  size,
                  content,
                  description: "Payment Slip",
                });
                this.loadingDoc = false;
              }

            if(["image/jpeg","image/png"].includes(type)) {
              this.resizeBase64Img(content, 2000, 2000, type).then(resized => {
                // code below is to show the resized photo in img tag
                // let img = document.createElement("img");
                // img.src = resized;
                // document.body.appendChild(img);
                let n = resized.length,
                  y = resized.substring(n-2) == '=='?2:1;
                size = (n * (3/4)) - y;
                content = resized;
                pushDoc();
              });
            } else {
              pushDoc();
            }
          });
        }
        input.value = "";
      }
      // else this.document = "";
    },
    toBase64(file) {
      // https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadDoc() {
      if(this.documents.length == 0){
        Swal.fire({
          icon: "error",
          text: "Please select a file to proceed.",
        });
        return
      } else if(this.description == ''){
        Swal.fire({
          icon: "error",
          text: "Please describe this upload.",
        }).then(() => document.getElementById('description').focus());
        return
      }
      let postData = {
        payamount: this.payamount,
        description: this.description,
        documents: this.documents,
        remark: this.remark
      };
      if('invoice' in this.payData) postData.invoice_id = this.payData.invoice.id;
      this.loading = true;
      this.$store
        .dispatch({
          type: "uploadDocument",
          dcid: this.dcid,
          direct_access: this.direct_access,
          payment: postData,
        })
        .then((r) => {
          if (r.error == false) {
            this.uploadSuccess = this.description;
            console.log('uploadProgress', this.uploadProgress);
            this.fetchData();
          } else {
            Swal.fire({
              icon: "error",
              text: "Something not right! Please try again.",
            });
          }
          this.loading = false;
          // if (r.error == false) {
          //   if (typeof r.eGHL != "undefined") {
          //     this.payment_id = r.payment_id;
          //     window.onmessage = (e) => {
          //       if (
          //         typeof e.data == "object" &&
          //         "type" in e.data &&
          //         e.data.type == "paymentDone"
          //       ) {
          //         this.checkPaymentResponse(e.data.response);
          //       }
          //     };
          //     this.$helpers.postForeign(r.eGHL_URL, r.eGHL);
          //   }
          // } else {
          //   if ("message" in r)
          //     Swal.fire({
          //       icon: "error",
          //       text: r.message,
          //     });
          //   else console.error(r);
          //   this.loading = false;
          // }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            text: e,
          });
          this.loading = false;
        });
    },
  },
  computed: {
    business() {
      return this.$store.state.business;
    },
    customer() {
      return this.$store.state.customer;
    },
    uploadProgress() {
      return this.$store.state.upload_progress;
    }
  },
  watch: {
    invoice(newValue) {
      this.payamount =
        this.$helpers.toCurrency(newValue.total_amount) -
        this.$helpers.toCurrency(newValue.total_paid);
    },
  },
  components: {
    NotFound: () => import(/* webpackChunkName: "404" */ "@/components/404"),
    PayInfoDialog: () => import(/* webpackChunkName: "PayInfoDialog" */ "@/components/PayInfoDialog"),
  },
};
</script>
<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

tr.up-to-date
  background-color: #e0e0e0
  font-style: italic

@media #{map-get($display-breakpoints, 'xs-only')}
  label.col-sm-3
    padding-bottom: 0
  div.col-sm-9
    padding-top: 0
</style>
