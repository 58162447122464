<template>
<div>
  <pay-online-component :key="componentKey" @reload="forceRerender" :cid="cid" :iid="iid"></pay-online-component>
</div>
</template>
<script>
import PayOnlineComponent from "@/components/PayOnline";

export default {
  props: {
    cid: {
      required: true,
    },
    iid: {
      default: null,
    },
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    forceRerender(){
      this.componentKey += 1;
    }
  },
  components: {
    PayOnlineComponent
  },
};
</script>