<template>
  <div>
    <v-row justify="center">
      <v-col v-if="noForm" cols="12" md="10" lg="8">
        <v-alert
          type="warning"
          prominent
          elevation="2"
        >
          No registration form found. Please contact us for other registration method.
        </v-alert>
        <business-profile></business-profile>
      </v-col>
      <v-col v-else-if="submitted.success" cols="12" md="10" lg="8">
        <v-card v-if="loaded">
          <v-card-title>{{ form.title ? form.title : 'Registration Form' }}</v-card-title>
          <v-card-text>
            <v-alert
              type="success"
              prominent
              elevation="2"
            >
              {{ submitted.message ? submitted.message : "Your form has been submitted." }}
            </v-alert>
          </v-card-text>
          <div style="overflow: auto;"></div>
          <v-card-actions class="pb-10 d-flex justify-center">
            <v-btn
              color="info"
              @click="viewPDF(submitted.pdf)"
            >
              <v-icon left>
                mdi-file-document-outline
              </v-icon>
              View PDF
            </v-btn>
            <v-btn
              color="danger"
              @click="resetForm()"
            >
              Reset Form
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-else cols="12" md="10" lg="8">
        <v-alert
          type="warning"
          prominent
          outlined
          v-if="preview_error"
        >
          <span v-if="!fpUser.id">You need to sign in to preview this registration form.</span>
          <span v-else>There is some problem while previewing this registration form.</span>
        </v-alert>
        <div v-if="preview_id && loaded">
          <v-alert
            prominent
            type="warning"
            elevation="2"
            class="mt-2"
          >
            This is a preview. If you are working on the settings, it is not save yet.
          </v-alert>
          <v-alert
            class="mb-5"
            type="info"
          >
            <v-row align="center">
              <v-col class="grow">
                Click View PDF to check the mandatory fields and view pdf output.<br />Click reload if you have made changes and the form is not updated.
              </v-col>
              <v-col class="shrink">
                <v-btn @click="fetchRegistrationForm">Reload</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>
        <v-card v-if="loaded">
          <v-card-title>{{ form.title ? form.title : 'Registration Form' }}</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row align="center" class="mx-0">
                <!-- <v-col cols="12" sm="6">
                  <v-text-field label="First Name"></v-text-field>
                  <v-text-field label="Last Name"></v-text-field>
                  <v-text-field label="identification Number"></v-text-field>
                  <v-text-field label="Date of Birth"></v-text-field>
                </v-col> -->
                <v-col
                  v-for="(field, index) in form.fields"
                  cols="12"
                  :sm="['header', 'divider', 'photo'].indexOf(field[0]) >= 0 ? 12 : 6"
                  :key="index"
                >
                  <p class="text-h4 mt-2 mb-0" v-if="field[0]=='header'">{{ field[1] }}</p>
                  <hr v-else-if="field[0] == 'divider'" />
                  <div v-else-if="field[0] == 'photo'">
                    <p class="text-h4 mt-2" :class="requirePhoto?'red--text mb-0':''">Photo</p>
                    <div v-if="requirePhoto" class="red--text mb-4 caption">Required.</div>
                    <div class="d-flex align-end">
                      <v-avatar color="indigo" size="100" tile @click="showUploadPicture = true">
                        <v-img v-if="photoSRC" :src="photoSRC"></v-img>
                        <v-icon v-else class="white--text">mdi-account-box</v-icon>
                      </v-avatar>
                      <div class="ml-2 d-flex align-end flex-column">
                        <v-btn
                          depressed
                          color="primary"
                          @click="showUploadPicture = true"
                          small
                          block
                        >
                          Select Photo
                        </v-btn>
                        <v-btn
                          v-if="photoSRC"
                          depressed
                          color="error"
                          small
                          class="mt-2"
                          block
                          @click="photoSRC = ''"
                        >
                          Remove Photo
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <v-select
                    v-else-if="field[0]=='preferred_lang'"
                    :items="[{value:'EN', text:'English'}, {value:'ZH', text:'中文'}, {value:'MS', text:'Bahasa Melayu'}]"
                    label="Preferred Language"
                    v-model="formData[field[0]]"
                    :return-object="false"
                  ></v-select>
                  <div v-else-if="field[0]=='dob'">
                    <v-menu  
                      ref="pickerMenu"
                      v-model="pickerMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formData[field[0]]"
                          hint="YYYY-MM-DD format"
                          :label="field[1]"
                          :name="field[0]"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData[field[0]]"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="savePicker"
                      >
                      <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="pickerMenu = false"
                        >
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <v-text-field
                    v-else
                    :label="field[1]"
                    :name="field[0]"
                    v-model="formData[field[0]]"
                    :rules="checkRules(field)"
                    :validate-on-blur="true"
                  ></v-text-field>
                  <!-- @blur="fieldBlurred(field[0])" -->
                  <!-- outlined
                  clearable
                  :rules="field[0] in rules?[rules[field[0]]]:[]""
                  filled -->
                </v-col>

                <v-col cols="12" v-if="form.services.display == 'c'">
                  <p class="text-h4 mt-2 mb-0">{{ form.services.title }}</p>
                  <v-select
                    :items="services"
                    :label="form.services.title"
                    multiple
                    chips
                    v-model="formData.services"
                  ></v-select>
                </v-col>
              </v-row>

              <template v-if="form.services.display == 't'">
                <p class="text-h4 mt-2">{{ form.services.title }}</p>
                
                <v-tabs
                  v-model="tab"
                  background-color="primary"
                  dark
                >
                  <v-tab
                    v-for="(group, index) in form.services.groups"
                    :key="index"
                  >
                    {{ group.name }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="(group, index) in form.services.groups"
                    :key="index"
                  >
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" v-for="(item) in group.services" :key="item[0]">
                            <v-checkbox
                              :value="index+'.'+item[0]"
                              :label="item[1]"
                              v-model="formData.services"
                              :data-value="'{value:'+index+'.'+item[0]+',text:'+item[1]+'}'"
                            ></v-checkbox>
                            <!-- :value="index+'.'+item[0]" -->
                            <!-- v-model="formData.services[String(index+'.'+item[0])]" -->
                            <!-- v-model="formData.services" -->

                            <!-- :value="{value:index+'.'+item[0],text:item[1]}"
                              :label="item[1]"
                              v-model="formData.services" -->
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </v-form>

            <hr />

            <v-row>
              <v-col cols=12 v-html="this.form.footer_note">
              </v-col>
              <v-col cols=12 v-if="form.acknowledgement">
                <v-form
                  ref="formAcknowledgement"
                  lazy-validation
                >
                  <v-checkbox
                    value="1"
                    :label="form.acknowledgement"
                    v-model="formData.acknowledgement"
                    :rules="[value => !!value || 'Please read and check the acknowledgement and consent statement to proceed.']"></v-checkbox>
                </v-form>
              </v-col>
              <v-col cols=12 v-if="form.require_sign">
                <!-- <canvas ref="canvas"></canvas> -->
                <div id="signature-pad" class="signature-pad">
                  <div class="signature-pad--body">
                    <canvas></canvas>
                  </div>
                  <div class="signature-pad--footer">
                    <div class="description">Sign above</div>
                    <div class="signature-pad--actions">
                      <div>
                        <v-btn @click="signatureClear">Clear</v-btn>
                        <v-btn @click="signatureUndo" class="ml-5">Undo</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <div style="overflow: auto;"></div>
          <v-card-actions v-if="preview_id" class="pb-10">
            <v-btn
                color="success"
                block
                large
                @click="previewPDF"
              >
                <v-icon left>
                  mdi-file-document-outline
                </v-icon>
                View PDF
              </v-btn>
          </v-card-actions>
          <v-card-actions v-else class="pb-10">
            <v-btn
              color="primary"
              large
              @click="registerSubmit"
            >
              Register Now
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click="resetForm"
            >
              Reset Form
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <upload-picture
      v-if="showUploadPicture"
      ref="uploadPicture"
      @save="savePicture"
      @close="showUploadPicture = false"
      dialogTitle="Upload Picture"
    />
  </div>
</template>
<script>
import SignaturePad from "signature_pad";
import Swal from "sweetalert2";

export default {
  props: {
    
  },
  data() {
    return {
      valid: false,
      tab: null,
      showUploadPicture: false,
      signaturePad: null,
      photoSRC: '',
      requirePhoto: false,
      loaded: false,
      noForm: false,
      submitted: {
        success: false,
        message: '', // message: 'Thank you for registering with us. We will review your request and get back to you as soon as possible.',
        pdf: '' // pdf: 'http://localhost/FeePlus/app/media/businesses/1/files/registrations/1_FP_RF1887.pdf'
      },
      preview_id: null, // if provided, will be the preview id
      preview_error: false,
      activePicker: null,
      pickerMenu: false,
      // form: {
      //   services: []
      // },
      services: [],
      formData: {
        services: []
      },
      rules: {
        required: value => !!value || 'Required.',
        // notRequired: value => {
        //   console.log('validate', value, value ? false : true)
        //   return value ? false : true
        // },
        email: value => {
          if(value){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          } else return true
        },
        p_mobile: value => {
          if(value){
            // console.log('Type of p_mobile (' + value + ') value: ' + typeof value); // + '; converted: ' + value.toString());
            // return false;
            value = value.match(/[\d+-]+/);
            this.$set(this.formData, 'p_mobile', value.toString()); // // 20231124 - bugfix text string become object .toString()
            const pattern = /^(\+?6)+[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
            return pattern.test(value) || 'Mobile number must start with 6 or +6 (e.g., 6012-3456789)'
          } else return true
        }
      },
    }

  },
  created(){
    this.fetchRegistrationForm()
    if(this.preview_id){
      // window.addEventListener('focus', this.fetchRegistrationForm);
      window.addEventListener("message", e => {
        if(typeof e.data === 'object' && 'feeplus' in e.data && e.data.feeplus == 'reload') {
          this.fetchRegistrationForm();
          if(this.$router.currentRoute.name !== "RegistrationForm") this.$router.go(-1)
        }
      })
    }
  },
  mounted() {
    // this.initSignaturePad() // init when data is loaded and require_sign is true
  },
  methods: {
    // fieldBlurred(field){
    //   switch(field){
    //     case "p_mobile":
    //       if(this.formData.p_mobile){
    //         this.$set(this.formData, 'p_mobile', this.formData.p_mobile.match(/[\d+-]+/));
    //       }
    //       break;
    //   }
    // },
    resetForm() {
      this.$set(this.submitted, 'success', false) // this.submitted.success = false
      // this.formData = {
      //   services: []
      // }
      // this.services = []
      setTimeout(() => {
        this.photoSRC = '' // reset picture
        this.$refs.form.reset()
        if(this.form.acknowledgement) this.$refs.formAcknowledgement.reset()
        if(this.form.require_sign) this.signatureClear()
        this.tab = null
        if(this.form.fields.find(field => field[0] == 'preferred_lang')) setTimeout(() => this.$set(this.formData, 'preferred_lang', "EN"), 150)
        // this.$vuetify.goTo('form')
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 150)
    },
    fetchRegistrationForm() {
      let q = this.$route.query;
      if ("preview_id" in q) {
        this.preview_id = q.preview_id
        this.$store.dispatch({
          type: 'fetchRegistrationForm',
          preview_id: this.preview_id
        })
        .then(() => {
          this.preview_error = false
          this.loaded = true
        })
        .catch(() => this.preview_error = true //this.$router.go(-1) //this.$router.push({name:"Home"})
        )
      } else {
        this.$store.dispatch({
          type: 'fetchRegistrationForm'
        }).then(r => {
          if(r.error == false && r.form_json) this.loaded = true
          else this.noForm = true
        })
      }
    },
    checkRules(field) {
      let rule = []
      if(field[2]) rule.push(this.rules.required); // else rule.push(this.rules.notRequired);
      if(field[0] in this.rules) rule.push(this.rules[field[0]]);
      // console.log(field, rule)
      return rule; //return field[0] in rules?[rules[field[0]]]:[]
    },
    savePicture(imageData) {
      this.photoSRC = imageData;
      this.requirePhoto = false
      this.$refs.uploadPicture.close();
    },
    cropSignatureCanvas(canvas) {
      // First duplicate the canvas to not alter the original
      var croppedCanvas = document.createElement('canvas'),
          croppedCtx    = croppedCanvas.getContext('2d');

      croppedCanvas.width  = canvas.width;
      croppedCanvas.height = canvas.height;
      croppedCtx.drawImage(canvas, 0, 0);

      // Next do the actual cropping
      var w         = croppedCanvas.width,
          h         = croppedCanvas.height,
          pix       = {x:[], y:[]},
          imageData = croppedCtx.getImageData(0,0,croppedCanvas.width,croppedCanvas.height),
          x, y, index;

      for (y = 0; y < h; y++) {
        for (x = 0; x < w; x++) {
          index = (y * w + x) * 4;
          if (imageData.data[index+3] > 0) {
            pix.x.push(x);
            pix.y.push(y);
          }
        }
      }
      pix.x.sort(function(a,b){return a-b});
      pix.y.sort(function(a,b){return a-b});
      var n = pix.x.length-1;

      w = pix.x[n] - pix.x[0];
      h = pix.y[n] - pix.y[0];
      var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

      croppedCanvas.width = w;
      croppedCanvas.height = h;
      croppedCtx.putImageData(cut, 0, 0);

      return croppedCanvas.toDataURL();
    },
    initSignaturePad() {
      
      var canvas = document.querySelector("canvas");

      this.signaturePad = new SignaturePad(canvas); //this.$refs.canvas);

      let resizeCanvas = () => {
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = parseInt(canvas.offsetWidth * 0.5) * ratio; //canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
      }
      window.addEventListener("resize", resizeCanvas);
      resizeCanvas();

      // // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
      // signaturePad.toDataURL(); // save image as PNG
      // signaturePad.toDataURL("image/jpeg"); // save image as JPEG
      // signaturePad.toDataURL("image/svg+xml"); // save image as SVG

      // // Draws signature image from data URL.
      // // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
      // signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");

      // // Returns signature image as an array of point groups
      // const data = signaturePad.toData();

      // // Draws signature image from an array of point groups
      // signaturePad.fromData(data);

      // // Clears the canvas
      this.signaturePad.clear();

      // // Returns true if canvas is empty, otherwise returns false
      // signaturePad.isEmpty();

      // // Unbinds all event handlers
      // signaturePad.off();

      // Rebinds all event handlers
      this.signaturePad.on();
    },
    signatureClear(){
      this.signaturePad.clear()
    },
    signatureUndo(){
      var data = this.signaturePad.toData();
      if (data.length) {
        data.pop(); // remove the last dot or line
        this.signaturePad.fromData(data);
      }
    },
    genData() {
      return new Promise((resolve, reject) => {
        let photo = this.form.fields.filter(f => f[0] == 'photo')
        if(photo && photo[2] && !this.photoSRC) this.requirePhoto = true // check photo require
        if(!this.$refs.form.validate() || this.requirePhoto){
          Swal.fire({icon:"warning",title:"There is an error in some field(s). Please check and resubmit."}).then(() => this.$vuetify.goTo('form'))
          reject()
          return
        }
        if(this.form.acknowledgement && !this.$refs.formAcknowledgement.validate()) { // this.formData.acknowledgement != "1"
          Swal.fire({icon:"warning",title:"Please read and check the acknowledgement and consent statement to proceed."}).then(() => this.$vuetify.goTo('formAcknowledgement'))
          reject()
          return
        }
        
        if(this.form.require_sign) {
          if(this.signaturePad.toData().length) this.formData.sign = this.cropSignatureCanvas(document.querySelector("canvas"))
          else {
            Swal.fire({
              icon: "warning",
              title: "Please sign to proceed.",
            })
            reject()
            return
          }
        }
        // process services
        let services = [], addServices = srv => { // service
          let id = srv.value.split('.'), group = this.form.services.groups[id[0]].name
          services.push([id[1], srv.text+(srv.text.includes(group)?'':` (${group})`)])
        }
        switch(this.form.services.display){
          case 'c':
            for(const service of this.formData.services) addServices(service)
            break;
          case 't':
            for(const service of this.services){
              if(this.formData.services.indexOf(service.value) >= 0) addServices(service)
            }
            break;
        }
        let data = Object.assign({}, this.formData)
        if(photo && this.photoSRC) data.photo = this.photoSRC
        data.services = services
        console.log('data', data)
        resolve(data)
      })
    },
    viewPDF(pdf_url = '') {
      this.$router.push({
        name: 'ViewPdf',
        params: {
          src: pdf_url
        }
      })
    },
    previewPDF() {
      this.genData().then(data => {
        this.$store.dispatch({
          type: "createRegistrationFormPDF",
          data: {data_json: JSON.stringify(data)},
          preview_id: this.preview_id
        }).then(r => {
          if(r.error == false){
            this.$router.push({
              name: 'ViewPdf',
              params: {
                src: r.pdf_url
              }
            })
          }
        })
      })
    },
    registerSubmit(){
      this.genData().then(data => {
        console.log(JSON.stringify(data))
        this.$store
          .dispatch({
            type: "createRegistrationForm",
            data: {data_json: JSON.stringify(data)},
          })
          .then((r) => {
            console.log('r',r)
            if (r.error == false) {
              this.submitted.success = true
              this.submitted.message = r.message
              this.submitted.pdf = r.pdf_url
            }
          });
      })
    },
    savePicker(date){
      this.$refs.pickerMenu[0].save(date)
    }
  },
  computed: {
    business() {
      return this.$store.state.business;
    },
    fpUser() {
      return this.$store.state.fp_user;
    },
    form() {
      return this.$store.state.registration_form;
    }
  },
  watch: {
    form(n){ // o
      // console.log('n', n)
      if(n){
        let len = n.services.groups.length;
        this.services = [];
        for(let i = 0; i < len; i++){
          let group = n.services.groups[i];
          this.services.push({header: group.name})
          for(const item of group.services){
            this.services.push({value: i+'.'+item[0], text: item[1]})
          }
        }
        if(n.fields.find(field => field[0] == 'preferred_lang')) this.$set(this.formData, 'preferred_lang', "EN") //this.formData.preferred_lang = "EN"
        if(n.require_sign) setTimeout(() => this.initSignaturePad(), 150)
      }
    },
    fpUser(n){
      // console.log('form', this.form)
      if(n.id && !('title' in this.form)) {
        this.fetchRegistrationForm()
        // setTimeout(() => this.$router.go(), 150)
      }
    },
    pickerMenu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    // formData: {
    //   handler(val){
    //     console.log('formData', val)
    //   }, deep: true
    // }
  },
  components: {
    UploadPicture: () => import(/* webpackChunkName: "UploadPicture" */ "@/components/UploadPicture"),
    BusinessProfile: () => import(/* webpackChunkName: "BusinessProfile" */ "@/components/BusinessProfile")
  },
};
</script>
<style scoped>
.signature-pad {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 10px;
  width: 100%;
  height: 100%;
  max-width: 700px;
  /* max-height: calc(700px * 0.5); /*460px;*/
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  padding: 16px;
}

.signature-pad::before,
.signature-pad::after {
  position: absolute;
  z-index: -1;
  content: "";
  width: 40%;
  height: 10px;
  bottom: 10px;
  background: transparent;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.signature-pad::before {
  left: 20px;
  -webkit-transform: skew(-3deg) rotate(-3deg);
          transform: skew(-3deg) rotate(-3deg);
}

.signature-pad::after {
  right: 20px;
  -webkit-transform: skew(3deg) rotate(3deg);
          transform: skew(3deg) rotate(3deg);
}

.signature-pad--body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 1px solid #f4f4f4;
}

.signature-pad--body
canvas {
  /* position: absolute; */
  
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.signature-pad--footer {
  color: #C3C3C3;
  text-align: center;
  font-size: 1.2em;
  margin-top: 8px;
}

.signature-pad--actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 8px;
}
</style>